:root {
  --primary: #323338;
  --primary-bg-color: #ffffff;
  --secondary: #297ccf;
  --secondary-text: rgba(0, 0, 0, 0.98);
  --bg-surface-color: #eceff8;
  --border-radius-medium: 8px;
  --font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --surface-box-shadow-xs: 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  --menuIcon-background-color: #f6f7fb;
  --rtDrawer-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  --table-shadow: 0px 1px 3px rgba(33, 150, 243, 0.3);
  --border-color: 1px solid #d0d4e4;
  --border-left-table: 6px solid #579bfc;
  --table-head: #eef1fd;
  --heatMaptable-head: #f67a050d;
  --bottom-border: 1px solid #d0d4e4;
  --bg-color-hover: #c4c4c424;
}

.amcForm-Grid .MuiGrid-item {
  padding-top: 10px !important;
}

.amcForm-Grid .MuiInput-root {
  padding: 0px !important;
  font-size: 14px !important;
}

.amcForm-Grid .MuiInputBase-input-MuiInput-input {
  padding: 0px !important;
  font-size: 14px !important;
}

.leadDetails-head {
  display: flex;
  justify-content: space-between;
  background-color: #4aa0f5 !important ;
  color: white !important;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 10px;
  font-size: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.lead-grid .MuiGrid-item {
  padding-top: 14px !important;
}

.checkbox-style .MuiTypography-root {
  font-size: 14px;
  font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.gridTable {
  border: var(--border-color) !important;
  box-shadow: var(--table-shadow) !important;
  border-radius: var(--border-radius-medium) !important;
  border-left: var(--border-left-table) !important;
}

.gridTable .MuiDataGrid-columnHeaders {
  background-color: var(--table-head) !important;
  color: var(--primary) !important;
  white-space: nowrap;
  font-family: var(--font-family) !important;
  border-right: var(--bottom-border) !important;
}

.gridTable .MuiDataGrid-iconSeparator {
  visibility: hidden;
}
.gridTable .MuiDataGrid-columnHeader {
  border-right: var(--bottom-border) !important;
}

.gridTable .MuiDataGrid-cell {
  font-family: var(--font-family) !important;
  border-bottom: var(--bottom-border) !important;
  border-right: var(--bottom-border) !important;
}

.submenu .MuiList-root {
  padding: 0px 0px !important;
}
.submenu .MuiListItemButton-root {
  font-family: var(--font-family) !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 0px !important;
  padding-right: 2px !important;
}
.submenu .MuiListItemIcon-root{
  min-width: 0px !important;
}
.submenu .MuiTypography-root{
  font-size: 15px;
}
.lead-submenu .MuiListItemButton-root {
  font-family: var(--font-family) !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 26px !important;
  padding-right: 0px !important;
}
.lead-submenu .MuiTypography-root{
  font-size: 13px;
}
@media screen and (max-width: 900px) {
  .amcForm-Grid .MuiGrid-item {
    padding-top: 4px !important;
  }
}

@media screen and (max-width: 450px) {
  .amcForm-Grid .MuiGrid-item {
    padding-top: 2px !important;
  }
  .amcForm-Grid .MuiInput-input {
    padding: 0px !important;
    font-size: 12px !important;
  }
  .leadDetails-head {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .lead-grid .MuiGrid-item {
    padding-top: 10px !important;
  }
  
.sidemenu .MuiListItemButton-root {
  font-family: var(--font-family) !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.submenu .MuiListItemButton-root {
  font-family: var(--font-family) !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 0px !important;
  padding-right: 2px !important;
}
.submenu .MuiTypography-root{
  font-size: 14px;
}
.lead-submenu .MuiListItemButton-root {
  font-family: var(--font-family) !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 26px !important;
  padding-right: 0px !important;
}
.lead-submenu .MuiTypography-root{
  font-size: 12px;
}
  .meeting-grid .MuiGrid-item {
    padding-left: 8px !important;
    padding-top: 16px !important;
  }

  .checkbox-style .MuiSvgIcon-root {
    font-size: 1.2rem;
  }
}

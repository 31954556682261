:root {
  --primary: #323338;
  --primary-bg-color: #ffffff;
  --secondary: #297ccf;
  --secondary-text: rgba(0, 0, 0, 0.98);
  --bg-surface-color: #eceff8;
  --border-radius-medium: 8px;
  --font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --surface-box-shadow-xs: 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  --menuIcon-background-color: #f6f7fb;
  --rtDrawer-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  --table-shadow: 0px 1px 3px rgba(33, 150, 243, 0.3);
  --border-color: 1px solid #d0d4e4;
  --border-left-table: 6px solid #579bfc;
  --table-head: #eef1fd;
  --heatMaptable-head: #f67a050d;
  --bottom-border: 1px solid #d0d4e4;
  --bg-color-hover: #c4c4c424;
}

body {
  margin: 0;
  font-family: var(--font-family) !important;
}

code {
  font-family: var(--font-family) !important;
}

/* common table */
.table {
  border: var(--border-color) !important;
  box-shadow: var(--table-shadow) !important;
  border-radius: var(--border-radius-medium) !important;
  border-left: var(--border-left-table) !important;
  height: 50vh !important;
}

.table-head .MuiTableCell-root {
  background-color: var(--table-head) !important;
  color: var(--primary) !important;
  white-space: nowrap;
  font-family: var(--font-family) !important;
  border-right: var(--bottom-border) !important;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.table-descrip {
  left: 0;
  background-color: var(--primary-bg-color);
  z-index: 2;
  height: 40px;
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.table-status {
  left: 0;
  background-color: var(--primary-bg-color);
  z-index: 2;
  height: 40px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.menu .MuiMenuItem-root {
  font-size: 14px;
  color: var(--primary);
  font-family: var(--font-family);
}

.table-body .MuiTableCell-root {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 12px;
  padding-right: 12px;
  font-family: var(--font-family) !important;
  border-bottom: var(--bottom-border) !important;
  border-right: var(--bottom-border) !important;
  white-space: nowrap;
  cursor: pointer;
}
/* heat map table */
.heatMap-table {
  border: var(--border-color) !important;
  box-shadow: var(--table-shadow) !important;
  border-radius: var(--border-radius-medium) !important;
  border-left: var(--border-left-table) !important;
  height: 60vh !important;
}

.heatMap-head .MuiTableCell-root {
  background-color: var(--table-head) !important;
  color: var(--primary) !important;
  white-space: nowrap;
  font-family: var(--font-family) !important;
  border-right: var(--bottom-border) !important;
  border-bottom: var(--bottom-border) !important;
  padding: 7px;
}
.heatMap-body .MuiTableCell-root {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0px;
  padding-right: 0px;
  font-family: var(--font-family) !important;
  border-bottom: var( --bottom-border) !important;
  border-right: var( --bottom-border) !important;
  white-space: nowrap;
  cursor: pointer;
}

.mui-spinner {
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  /* background-color: #000000b5; */
}

.task-detail .MuiInputBase-root {
  padding: 0px;
}
.task-detail .MuiInputBase-input {
  font-size: 14px !important;
  color: var(--secondary-text) !important;
  padding: 10px 14px !important;
}

.duedate .MuiInputBase-input {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 14px !important;
  padding-right: 0px !important;
}

.duedate .MuiInputAdornment-root {
  margin-right: 8px !important;
}

.task-detail .MuiSelect-select {
  padding: 8.5px 14px !important;
  font-size: 14px !important;
  color: var(--secondary-text) !important;
}

.task-detail .MuiGrid-item {
  padding-top: 12px !important;
}

.drawerhead .MuiPaper-root {
  height: calc(100vh - 64px) !important;
  background: linear-gradient(135deg, #ffffff 5%, var(--bg-surface-color) 100%);
  border-top-right-radius: var(--border-radius-medium);
}

.toolbar .MuiToolbar-root {
  min-height: 54px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.loading{
  font-size: 0.1px !important;
}

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 95vh;
  max-width: 100%;
}

.modal .MuiBackdrop-root {
  background: rgb(0 0 0 / 25%) !important;
}

.drawer .MuiDrawer-paper{
  width: 500px !important;
  border-top-left-radius: var( --border-radius-medium);
  border-right: none !important;
  border-bottom: none !important;
  border: 2px solid #d5daff;
  box-shadow: 0px 3px 6px rgba(33, 150, 243, 0.3);
  background-color: #fcfbfb;
  overflow-x: hidden;
  top: auto;
  bottom: 0;
  height:calc(100vh - 64px) !important ;
}
.drawer .MuiBackdrop-root {
  background: none !important;
}

.modal .MuiRadio-root{
  padding: 5px !important;
}

.task-detail .MuiInputLabel-root {
  top: -8px !important;
}

.task-detail .MuiInputLabel-shrink {
  top: -0px !important;
}

.detail-drawer .MuiInput-root {
  padding: 0px !important;
  font-size: 14px !important;
}

.detail-drawer .MuiInput-input {
  padding: 0px !important;
  font-size: 14px !important;
}
.css-j2o860-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important;
  padding-bottom: 10px !important;
}
.sidemenu .MuiListItemIcon-root {
  min-width: 40px !important;
}

.css-1v4xxck-MuiPaper-root-MuiAppBar-root {
  background-color: var(--bg-surface-color) !important;
  box-shadow: none !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
  font-size: 14px !important;
  color: var(--secondary-text) !important;
}

.css-hkv6ae {
  background-color: var(--primary-bg-color) !important;
}
.css-g5qzas {
  margin: 0 !important;
}

.css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-top: 12px !important;
}

.css-3z75l1 {
  height: calc(100vh - 64px) !important;
}

body > iframe {
  z-index: -1 !important;
}
@media screen and (max-width: 450px) {
  .drawer .MuiDrawer-paper{
    width: 330px !important;
    padding-left: var(--border-radius-medium);
    padding-right: var(--border-radius-medium);
  }
}
@media screen and (max-width: 450px) {
  .css-4ctdki {
    background-color: var(--primary-bg-color) !important;
  }
  .table-head .MuiTableCell-root {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .table-body .MuiTableCell-root {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
  }
  .heatMap-head .MuiTableCell-root {
    padding: 5px;
  }
  .heatMap-body .MuiTableCell-root {
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .modal .MuiIconButton-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-left: -6px !important;
  }
  .filterIcon .MuiSvgIcon-root {
    font-size: 20px !important;
  }

  .toolbar .MuiToolbar-root {
    min-height: 54px !important;
    padding-left: 14px;
    padding-right: 4px;
  }
  .task-detail .MuiInputBase-input {
    font-size: 12px !important;
  }
  .detail-drawer .MuiInput-input {
    font-size: 12px !important;
  }
}
